const colNumber = 4;
$(function() {
    $('.products-carousel').each(function () {
        const carousel = $(this);
        const items = carousel.children().detach();
        const stage = $('<div></div>').addClass('carousel-stage').appendTo(carousel);
        const container = $('<div></div>').addClass('products-container').appendTo(stage).css('left', 0);
        items.appendTo(container);
        // Define a altura do stage como a altura do maior item
        stage.css('height', items.toArray().reduce((max, i) => Math.max(max, $(i).height()), 0) + 10);
        container.css('height', '100%');

        // Cria os botões de navegação
        const navigation = $('<div></div>').addClass('carousel-navigation').appendTo(carousel);
        const previous = $('<div></div>')
            .addClass('carousel-previous')
            .appendTo(navigation)
            .hide()
            .on('click', function () {
                const left = parseInt(container.css('left'));
                if (left >= 0) return;
                const width = stage.width() / colNumber;
                container.css('left', left + width);
                next.show();
                if (left + width >= 0) $(this).hide();
            });
        const next = $('<div></div>').addClass('carousel-next').appendTo(navigation)
            .on('click', function () {
                const left = parseInt(container.css('left'));
                const width = stage.width() / colNumber;
                if (-left >= (items.length - colNumber) * width) return;
                container.css('left', left - width);
                previous.show();
                if (-(left - width) >= (items.length - colNumber) * width) $(this).hide();
            });

        stage.on('resize', function() {
            const width = stage.width();
            container.css({
                width: (width / colNumber) * items.length,
            });
            if (items.length <= colNumber) {
                previous.hide();
                next.hide();
            }
        }).trigger('resize');
    })
});